import React from 'react';
import { injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './SellerInfo.module.css';
import { AvatarLarge } from '../Avatar/Avatar';

import facebookIcon from '../../assets/facebook.svg';
import tiktokIcon from '../../assets/tiktok.svg';
import instagramIcon from '../../assets/instagram.svg';
import { isStoreOwner } from '../../util/userHelpers';

const SellerInfoComponent = ({
    onToggleFavoriteStore,
    user,
    currentUser
}) => {

    const { displayName, publicData } = user?.attributes?.profile || {};
    const {
        address, store_phone_number, store_website,
        store_hours, store_name = "", storeName = "", facebook_url = '',
        instagram_url = '', tiktok_url = '', store_logo = ""
    } = publicData || {};
    const storeNameFound = store_name || storeName || displayName;

    const renderStoreHours = (store_hours) => {
        return store_hours?.split('\n')?.map((line, index, hours) => (
            <span className={css.storeDetailsData} key={index}>
                {`${line}${index < hours.length - 1 ? ',' : ''}`}
                <br />
            </span>
        ));
    };

    const { privateData, publicData: currentUserPublicData } = currentUser?.attributes?.profile || {};
    const isFavorite = privateData?.favoriteStores?.includes(user?.id?.uuid);

    const toggleFavoriteStore = () => onToggleFavoriteStore(isFavorite);

    const getHttpsUrl = (baseUrl) => {
        return baseUrl?.startsWith('https://') ? baseUrl : `https://${baseUrl}`
    }
    const googleMapAddress = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

    const storeLogo = <div className={css.favouriteContainer}>
        {
            store_logo
                ? <img src={store_logo} className={css.storeLogo} />
                : <p className={css.favouriteName}>{storeNameFound}</p>
        }
        {
            !isStoreOwner(currentUserPublicData?.userType)
                ? <button type='button' onClick={toggleFavoriteStore} className={css.favouriteButton}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M10.567 19.0534L10.5662 19.0527C8.05943 16.7796 6.01894 14.9259 4.59931 13.1891C3.18518 11.459 2.43945 9.90601 2.43945 8.24251C2.43945 5.52289 4.56167 3.40918 7.27279 3.40918C8.80891 3.40918 10.2915 4.12686 11.257 5.25179L11.6364 5.69386L12.0158 5.25179C12.9813 4.12686 14.4639 3.40918 16.0001 3.40918C18.7112 3.40918 20.8334 5.52289 20.8334 8.24251C20.8334 9.90601 20.0877 11.459 18.6735 13.1891C17.2539 14.9259 15.2134 16.7796 12.7066 19.0527L12.7059 19.0534L11.6364 20.027L10.567 19.0534Z"
                            fill={isFavorite ? "#973132" : "white"}
                            stroke="#973132" />
                    </svg>
                </button>
                : null
        }

    </div>

    return (
        <div className={css.container}>
            <div className={css.profileSections}>
                {/* mobile and tablet view for store logo*/}
                <div className={css.favouriteContainerSm}>
                    {storeLogo}
                </div>

                <div className={css.profileSection}>
                    <div className={css.redSlop}>
                        <AvatarLarge className={css.profilePicture} user={user} disableProfileLink />
                        {displayName ? <p className={css.profileName}>{displayName}</p> : null}
                    </div>
                </div>
                <div className={css.storeInfoSection}>
                    {(storeNameFound) ? <h2 className={css.storeName}>{storeNameFound}</h2> : null}
                    <address className={css.storeDetails}>
                        {address
                            ? <p className={css.storeDetailsMain}>
                                Address:

                                <a href={googleMapAddress} className={css.storeDetailsData} target="_blank" rel="noopener noreferrer">{' '}{address}</a>

                            </p>
                            : null
                        }
                        {store_phone_number ? <p className={css.storeDetailsMain}>Phone:<a className={css.storeDetailsData} href={`tel:${store_phone_number}`}>{' '}{store_phone_number}</a></p> : null}
                        {store_website ? <p className={css.storeDetailsMain}>Website:<a className={css.storeDetailsData} href={getHttpsUrl(store_website)} target="_blank">{' '}{store_website}</a></p> : null}
                        {store_hours ?
                            <div className={css.storeDetailsMain}>
                                Hours:{' '}
                                {renderStoreHours(store_hours)}

                            </div>
                            : null}
                    </address>
                    <div className={css.socialMediaIcons}>
                        <div className={css.socialIcons}>
                            {
                                facebook_url
                                    ? <a href={getHttpsUrl(facebook_url)} target="_blank" rel="noopener noreferrer">
                                        <img src={facebookIcon} alt='facebook-icon' />
                                    </a>
                                    : null
                            }
                            {
                                instagram_url
                                    ? <a href={getHttpsUrl(instagram_url)} target="_blank" rel="noopener noreferrer">
                                        <img src={instagramIcon} alt='instagram-icon' />
                                    </a>
                                    : null
                            }

                            {
                                tiktok_url
                                    ? <a href={getHttpsUrl(tiktok_url)} target="_blank" rel="noopener noreferrer">
                                        <img src={tiktokIcon} alt='tiktok-icon' />
                                    </a>
                                    : null
                            }
                        </div>
                    </div>
                    <div>
                    </div>
                    {/* desktop-view for store logo */}
                    <div className={css.favouriteContainerLg}>
                        {storeLogo}
                    </div>
                </div>
            </div>
        </div>
    );
}


SellerInfoComponent.defaultProps = {
    onToggleFavoriteStore: null,
    currentUser: null,
    user: null,
};

SellerInfoComponent.propTypes = {
    onToggleFavoriteStore: propTypes.onToggleFavoriteStore,
    currentUser: propTypes.currentUser,
    user: propTypes.user,
};

const SellerInfo = injectIntl(SellerInfoComponent);

export default SellerInfo;